import React from 'react';
import "animate.css/animate.min.css"
import SBDStyle from './SBoardDescription.module.css'
import Cover from '../assets/Dispatcher_panel.png'
import SBoardRelatedCards from "./SBoardRelatedCards"
import SB_Footer from './SB_Description_Footer'

class SBDispatcherDescription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={SBDStyle.container}>
                <div style={{color: '#0f0f0f', background: '#f0f0f0'}}>
                    <div className={SBDStyle.titleContainer} style={{color: '#0f0f0f'}}>
                        <h2>Dispatcher</h2>
                        <h3>Send a cv to 4 destinations</h3>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <img src={Cover} className={SBDStyle.banner} style={{maxHeight: '372px', width: 'auto'}}/>
                        <div style={{height: "16px"}}/>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <p className={SBDStyle.slogan}>CV dispatching module</p>
                        <div style={{height: "30px"}}/>
                    </div>
                    <div className={SBDStyle.cartArea}>
                        <div className={SBDStyle.cartAreaContent}>
                            <div>
                                <p style={{color: '#6f747f', fontWeight: 800}}>Modifier</p>
                                <p style={{fontSize: '1.8rem', textTransform: 'uppercase', fontWeight: 800}}>Dispatcher</p>
                                <div className={SBDStyle.currPrice}>US $9</div>
                            </div>          
                            <div className={SBDStyle.buttonWrapper} style={{textAlign:'center'}}>
                                <p style={{paddingTop: '0.6rem'}}>Must use with S-Board!</p>
                                <button onClick={this.props.addToCart} className={SBDStyle.addToCartButton}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>Overview</h2>
                    </div>
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Dead simple, yet very useful</h4>
                        <p>Dispatcher is a CV bus, it dispatches the same input to 4 separate destinations, use it when you want synchronized control across multiple parameters.</p>
                    </div>
                    <div style={{height: "60px"}}/>  
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Super light on Cpu</h4>
                        <p>You can drag any number of dispatchers on to S-Board without worrying about CPU usage, like its compact footprint, it's super lightweight and barely takes any CPU cycles.</p>
                    </div>
                </div>
                <SBoardRelatedCards currentItem="dispatcher"/>

                <SB_Footer getTrial={this.props.getTrial}/>
            </div>   
        )
    }
}

export default SBDispatcherDescription